<template>
  <div class="navbar-hide-on-scroll">
    <p
      v-if="caregiverInfo.firstname"
      class=""
    >
      <span class="text-capitalize h4">hello {{ caregiverInfo.firstname }},</span>
      <span class=""> This is what we've got for you.</span>
    </p>
    <b-row>
      <b-col md="12">
        <caregiver-statistics />
      </b-col>
      <b-col
        lg="8"
        md="12"
      >
        <b-overlay
          :show="isShiftInfoLoading"
          rounded="sm"
          spinner-type="grow"
        >
          <b-card>
            <!-- Select Status -->
            <div class="d-flex align-items-center justify-content-between">
              <h3>My Shifts</h3>
              <b-button
                v-if="tabIndex != 2"
                variant="outline-primary"
                class="float-right"
                @click="goToCalendar()"
              >
                <span class="align-middle">View in Calendar</span>
              </b-button>
              <v-select
                v-if="tabIndex === 2"
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                placeholder="Select Status"
                :clearable="false"
                class="filter-select"
              >

                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>

              </v-select>
            </div>
            <!-- <small>Current Tab: {{ tabIndex }}</small> -->
            <b-tabs
              v-model="tabIndex"
            >
              <!-- Tab: Scheduled -->
              <b-tab
                active
              >
                <template #title>
                  <span class="d-none d-sm-inline">Scheduled</span>
                </template>
                <b-list-group
                  id="shift-scheduled"
                  flush
                  class="border-bottom shift-sched"
                >
                  <b-list-group-item
                    v-for="(item, index) in scheduledShifts"
                    id="shift-scheduled-list"
                    :key="index"
                    class="d-flex justify-content-between align-items-center cursor-pointer"
                    @click="
                      $router.push({
                        name: 'agenttasks-tasks',
                        params: { id: item.shiftID },
                        query: { date: moment(item.start).format('YYYY-MM-DD HH:mm:ss') }
                      })
                    "
                  >
                    <b-media vertical-align="top">
                      <template #aside>
                        <b-avatar
                          rounded
                          variant="primary"
                          size="34"
                        >
                          <feather-icon
                            icon="CalendarIcon"
                            size="18"
                          />
                        </b-avatar>
                      </template>
                      <b-card-text class="mb-0 font-weight-bolder">
                        {{ moment(item.start).format('ddd, MMM D, YYYY') }}
                      </b-card-text>
                      <small class="text-muted">
                        {{ moment(item.start_time).format('h:mm a') }}
                        -
                        {{ moment(item.end_time).format('h:mm a') }}
                      </small>
                    </b-media>
                    <div class="">
                      <b-media
                        right-align
                        vertical-align="top"
                        class="text-right"
                      >
                        <template #aside>
                          <b-avatar
                            size="32"
                            rounded
                            variant="primary"
                            :src="`${$urlUpload}clients/${item.clientPicture}`"
                            :text="avatarText(item.client_name)"
                          />
                        </template>
                        <b-card-text class="mb-0">
                          {{ item.client_name }}
                        </b-card-text>
                        <b-badge
                          v-if="item.status === '2'"
                          variant="light-warning"
                          pill
                          class="badge-round"
                        >
                          <span>In progress</span>
                        </b-badge>
                        <b-badge
                          v-if="item.status === '3'"
                          variant="light-success"
                          pill
                          class="badge-round"
                        >
                          <span>Completed</span>
                        </b-badge>
                      </b-media>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item v-if="scheduledShifts.length === 0">
                    <div class="d-flex flex-row justify-content-center align-items-center">
                      <h5>No records found</h5>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-tab>

              <!-- Tab: Open -->
              <b-tab>
                <template #title>
                  <span class="d-none d-sm-inline">Open</span>
                </template>
                <b-list-group
                  flush
                  class="border-bottom"
                >
                  <b-list-group-item
                    v-for="(item, index) in openShifts"
                    :key="index"
                    class="d-flex justify-content-between align-items-center cursor-pointer"
                    @click="
                      $router.push({
                        name: 'agenttasks-tasks',
                        params: { id: item.shiftID },
                        query: { date: moment(item.start).format('YYYY-MM-DD HH:mm:ss') }
                      })
                    "
                  >
                    <b-media vertical-align="top">
                      <template #aside>
                        <b-avatar
                          rounded
                          variant="primary"
                          size="34"
                        >
                          <feather-icon
                            icon="CalendarIcon"
                            size="18"
                          />
                        </b-avatar>
                      </template>
                      <b-card-text class="mb-0 font-weight-bolder">
                        {{ moment(item.start).format('ddd, MMM D, YYYY') }}
                      </b-card-text>
                      <small class="text-muted">
                        {{ moment(item.start_time).format('h:mm a') }}
                        -
                        {{ moment(item.end_time).format('h:mm a') }}
                      </small>
                    </b-media>
                    <div class="">
                      <b-media
                        right-align
                        vertical-align="top"
                        class="text-right"
                      >
                        <template #aside>
                          <b-avatar
                            size="32"
                            rounded
                            variant="primary"
                            :src="`${$urlUpload}clients/${item.clientPicture}`"
                            :text="avatarText(item.client_name)"
                          />
                        </template>
                        <b-card-text class="mb-0">
                          {{ item.client_name }}
                        </b-card-text>
                      </b-media>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item v-if="openShifts.length === 0">
                    <div class="d-flex flex-row justify-content-center align-items-center">
                      <h5>No records found</h5>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-tab>

              <!-- Tab: History -->
              <b-tab>
                <template #title>
                  <span class="d-none d-sm-inline">History</span>
                </template>
                <!-- Table -->
                <b-table
                  ref="refMyShiftsListTable"
                  primary-key="id"
                  show-empty
                  :fields="fields"
                  :items="historyShifts"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  thead-class="d-none"
                  empty-text="No records found"
                  responsive
                  hover
                  class=""
                  @row-clicked="viewTasks"
                >
                  <template #cell(data)="data">
                    <div class="d-flex justify-content-between align-items-center">
                      <b-media vertical-align="top">
                        <template #aside>
                          <b-avatar
                            rounded
                            variant="primary"
                            size="34"
                          >
                            <feather-icon
                              icon="CalendarIcon"
                              size="18"
                            />
                          </b-avatar>
                        </template>
                        <b-card-text class="mb-0 font-weight-bolder">
                          {{ moment(data.item.start).format('ddd, MMM D, YYYY') }}
                        </b-card-text>
                        <small class="text-muted">
                          {{ moment(data.item.start_time).format('h:mm a') }}
                          -
                          {{ moment(data.item.end_time).format('h:mm a') }}
                        </small>
                      </b-media>
                      <div class="">
                        <b-media
                          right-align
                          vertical-align="top"
                          class="text-right"
                        >
                          <template #aside>
                            <b-avatar
                              rounded
                              variant="primary"
                              size="32"
                              :src="`${$urlUpload}clients/${data.item.clientPicture}`"
                              :text="avatarText(data.item.client_name)"
                            />
                          </template>
                          <b-card-text class="mb-0 font-weight-bolder">
                            {{ data.item.client_name }}
                          </b-card-text>
                          <b-badge
                            v-if="data.item.status === '3'"
                            variant="light-success"
                            pill
                            class="badge-round"
                          >
                            <span>Completed</span>
                          </b-badge>
                          <b-badge
                            v-if="data.item.status === '4'"
                            variant="light-danger"
                            pill
                            class="badge-round"
                          >
                            <span>Missed Clock-In</span>
                          </b-badge>
                          <b-badge
                            v-if="data.item.status === '5'"
                            variant="light-danger"
                            pill
                            class="badge-round"
                          >
                            <span>Missed Clock-Out</span>
                          </b-badge>
                        </b-media>
                      </div>
                    </div>
                  </template>
                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="
                      d-flex
                      align-items-center
                      justify-content-center justify-content-sm-start
                    "
                    >
                      <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="
                      d-flex
                      align-items-center
                      justify-content-center justify-content-sm-end
                    "
                    >
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        lg="4"
        md="12"
      >
        <b-overlay
          :show="isCaregiverProfileLoading"
          rounded="sm"
          spinner-type="grow"
        >
          <caregiver-profile
            :caregiver-info="caregiverInfo"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <!-- History Shift Handler -->
    <shift-history-handler-sidebar
      v-model="isShiftHistoryHandlerSidebarActive"
      :shift-history="shiftHistory"
    />
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BMedia,
  BAvatar,
  BTab, BTabs,
  BBadge,
  BTable,
  BPagination,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { formatDate, avatarText } from '@core/utils/filter'
import caregiverDashboardStoreModule from './caregiverDashboardStoreModule.js'
import CaregiverStatistics from './CaregiverStatistics'
import CaregiverProfile from './CaregiverProfile'
import ShiftHistoryHandlerSidebar from './ShiftHistoryHandlerSidebar'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BMedia,
    BAvatar,
    BTab,
    BTabs,
    BBadge,
    BButton,
    BTable,
    BOverlay,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    CaregiverStatistics,
    CaregiverProfile,
    ShiftHistoryHandlerSidebar,
  },
  unmounted() {
    this.style.remove()
  },
  setup() {
    const CAREGIVER_DASHBOARD_APP_STORE_MODULE_NAME = 'caregiver_dashboard'

    // Register module
    if (!store.hasModule(CAREGIVER_DASHBOARD_APP_STORE_MODULE_NAME)) {
      store.registerModule(CAREGIVER_DASHBOARD_APP_STORE_MODULE_NAME, caregiverDashboardStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAREGIVER_DASHBOARD_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CAREGIVER_DASHBOARD_APP_STORE_MODULE_NAME)
      }
    })

    const isShiftInfoLoading = ref(true)
    const isCaregiverProfileLoading = ref(true)
    const uDATA = JSON.parse(localStorage.getItem('userData'))
    const tabIndex = ref(null)
    const caregiverInfo = ref(computed(() => store.state.caregiver_dashboard.caregiverInfo))
    const scheduledShifts = ref(computed(() => store.state.caregiver_dashboard.scheduledShifts))
    const openShifts = ref(computed(() => store.state.caregiver_dashboard.openShifts))
    // TABLE
    const historyShifts = ref(computed(() => store.state.caregiver_dashboard.historyShifts))
    const refMyShiftsListTable = ref(null)
    const fields = ref(computed(() => store.state.caregiver_dashboard.fields))
    // SIDEBAR
    const shiftHistory = ref({})
    const isShiftHistoryHandlerSidebarActive = ref(false)
    // PAGINATION
    const perPage = ref(20)
    const totalItems = ref(computed(() => store.state.caregiver_dashboard.totalItems))
    // const totalItems = ref(0)
    const currentPage = ref(1)
    // FILTERING
    const statusFilter = ref([{ label: 'Show all', value: '' }])
    const statusOptions = ref([
      { label: 'Show all', value: '' },
      { label: 'Completed', value: '3' },
      { label: 'Missed Clock-in', value: '4' },
      { label: 'Missed Clock-out', value: '5' },
    ])
    // SORTING
    const sortBy = ref('')
    const isSortDirDesc = ref(false)

    // *===============================================---*
    // *----------------- RETRIEVE DATA ------------------*
    // *===============================================---*
    /* retrieve shifts with tasks */
    const fetchMyData = () => {
      isShiftInfoLoading.value = true
      store.dispatch('caregiver_dashboard/request')
        .then(response => {
          // console.log('some response message', response.data.response.data)
          store.dispatch('caregiver_dashboard/filterData', response.data.response.data)
          isShiftInfoLoading.value = false
        })
        .catch(() => {
          // console.log('some error message', er)
          isShiftInfoLoading.value = false
        })
    }
    /* retrieve caregiver information */
    const fetchMyCaregiverData = () => {
      isCaregiverProfileLoading.value = true
      store.dispatch('caregiver_dashboard/requestCaregiverInfo')
        .then(response => {
          store.commit('caregiver_dashboard/SET_CAREGIVER_INFO', response.data.response.data)
          if (response.data.response.data.profilepicture !== '') {
            store.commit('account_setting/SET_PROF_PIC', `${this.$urlUpload}${response.data.response.data.id}/${response.data.response.data.profilepicture}`)
          }
          // console.log('some response message', response.data.response.data)
          isCaregiverProfileLoading.value = false
        })
        .catch(() => {
          // console.log('some error message', er)
          isCaregiverProfileLoading.value = false
        })
    }
    fetchMyData()
    fetchMyCaregiverData()

    // *=========================================---*
    // *----------------- SOCKET -------------------*
    // *=========================================---*
    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'
    const getSockMsg = sockMsg => {
      if (sockMsg === true) {
        // Trigger reload or something here
        // console.log(typeof sockMsg, sockMsg)
        fetchMyData()
      }
    }

    const initializeSockJs = () => {
      sock.onopen = () => {
      // console.log('Connection established.')
      }
      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === sockId) {
          getSockMsg(data.msg)
        }
      }
      sock.onclose = () => {
      // console.warn('Connection closed.')
        SockJS.call(sock, sock.url)
      }
    }
    initializeSockJs()

    // *===============================================---*
    // *-------------------- FILTER ----------------------*
    // *===============================================---*
    watch([statusFilter], val => {
      store.dispatch('caregiver_dashboard/filterByShiftStatus', val)
      // console.log('some val', val)
      // totalItems.value = rawHistoryItems.value.length
    })
    // *===============================================---*
    // *------------------ Pagination --------------------*
    // *===============================================---*
    const dataMeta = computed(() => {
      const localItemsCount = refMyShiftsListTable.value ? refMyShiftsListTable.value.paginatedItems.length : 0
      // console.log('refMyShiftsListTable.value.localItems', refMyShiftsListTable.value)
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      }
    })

    const refetchData = () => {
      refMyShiftsListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    // *===============================================---*
    // *----------- Open HIstory Shift sidebar -----------*
    // *===============================================---*
    const viewTasks = record => {
      shiftHistory.value = record
      // console.log('record: ', record)
      isShiftHistoryHandlerSidebarActive.value = !isShiftHistoryHandlerSidebarActive.value
    }

    watch(isShiftHistoryHandlerSidebarActive, val => {
      const appContainer = document.querySelector('#app')
      if (!val) {
        appContainer.style.overflow = ''
      } else {
        appContainer.style.overflow = 'hidden'
      }
    })

    return {
      pageTitle: 'Caregiver Dashboard',
      isShiftInfoLoading,
      isCaregiverProfileLoading,
      uDATA,
      tabIndex,
      caregiverInfo,
      scheduledShifts,
      openShifts,
      fetchMyData,
      viewTasks,
      // caregiverLicenses,
      // UI
      avatarText,
      formatDate,
      moment,
      // TABLE
      refMyShiftsListTable,
      historyShifts,
      fields,
      // SIDEBAR
      shiftHistory,
      isShiftHistoryHandlerSidebarActive,
      // PAGINATION
      perPage,
      totalItems,
      currentPage,
      dataMeta,
      // FILTERING
      statusFilter,
      statusOptions,
      // SORTING
      sortBy,
      isSortDirDesc,
    }
  },
  methods: {
    goToCalendar() {
      this.$router.push('/caregiver-shift-calendar')
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.filter-select {
  min-width: 200px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 130px;
  }
}
</style>
<style scoped>
*>>> h1{
  margin: auto;
}

*>>> .caregiver-grad {
  overflow: hidden;
}
*>>> .caregiver-grad > img {
   visibility: hidden;
   height: 115px;
}
*>>> .caregiver-grad::before {
    content: "";
    width: 100%;
    background-image: linear-gradient(to right, #B8D240 , #008483);
    height:130px;
    position:absolute;
}
*>>> .nav-tabs .nav-link.active:after{
background: transparent linear-gradient(93deg, #B9D23E 0%, #569099 100%) 0% 0% no-repeat padding-box !important;
}
</style>
