<template>
  <b-card
    no-body
    :img-src="require('@/assets/images/banner/banner-12.jpg')"
    class="card-profile caregiver-grad"
  >
    <b-card-body
      class="pb-0"
    >
      <div class="profile-image-wrapper">
        <div class="profile-image-caregiver borderimg">
          <b-avatar
            square
            variant="primary"
            size="100"
            :src="`${$urlUpload}${caregiverInfo.id}/${caregiverInfo.profilepicture}`"
            :text="avatarText(`${caregiverInfo.firstname.slice(0,1)} ${caregiverInfo.lastname.slice(0,1)}`)"
          />
        </div>
      </div>
      <h3>{{ caregiverInfo.firstname }} {{ caregiverInfo.lastname }}</h3>
    </b-card-body>
    <b-card-body class="p-1">
      <b-row
        v-for="(item, index) in caregiverLicenses(caregiverInfo.license)"
        :key="index"
        class="px-1 mb-1"
      >
        <b-col
          md="5"
          class="text-left"
        >
          <b-img
            v-if="index === 0"
            :src="require('@/assets/images/icons/id_icon.svg')"
            alt="icon-license"
            class="gray-1"
          />
          <span
            class="font-weight-bolder gray-1"
            :style="index !== 0 ? 'margin-left: 25px' : 'margin-left: 5px'"
          >
            {{ item.id }}
          </span>
        </b-col>
        <b-col
          md="7"
          class="text-left"
        >
          <div
            class="p-0 m-0 row"
          >
            <span>{{ item.no }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mb-1">
        <b-col
          md="5"
          class="text-left"
        >
          <feather-icon
            size="16"
            icon="HomeIcon"
            class="gray-1"
          />
          <span
            class="font-weight-bolder gray-1"
            style="margin-left: 5px"
          >
            Address:
          </span>
        </b-col>
        <b-col
          md="7"
          class="text-left"
        >
          <span class="">
            {{ caregiverInfo.street ? `${caregiverInfo.street},` : '' }}
            {{ caregiverInfo.citytown ? `${caregiverInfo.citytown},` : '' }}
            {{ caregiverInfo.state ? `${caregiverInfo.state}` : '' }}
            {{ caregiverInfo.zipcode ? `${caregiverInfo.zipcode}` : '' }}
          </span>
        </b-col>
      </b-row>
      <b-row class="px-1 mb-1">
        <b-col
          md="5"
          class="text-left"
        >
          <feather-icon
            size="16"
            icon="PhoneIcon"
            class="gray-1"
          />
          <span
            class="font-weight-bolder gray-1"
            style="margin-left: 5px"
          >
            Contact #:
          </span>
        </b-col>
        <b-col
          md="7"
          class="text-left"
        >
          <span class="">
            {{ caregiverInfo.phonenumber ? caregiverInfo.phonenumber : caregiverInfo.mobilenumber }}
          </span>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BCardBody, BImg,
} from 'bootstrap-vue'
// import axios from '@axios'
// import Vue from 'vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BAvatar,
  },
  props: {
    caregiverInfo: {
      type: Object,
      required: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  setup() {
    // const caregiverInfo = ref()

    const caregiverLicenses = license => {
      const licenses = []
      if (license) {
        // if (license.licenseNoCNA !== '') licenses.push({ id: 'CNA', no: license.licenseNoCNA })
        if (license.licenseNoHCA !== '') licenses.push({ id: 'HCA', no: license.licenseNoHCA })
        if (license.licenseNoHHA !== '') licenses.push({ id: 'HHA', no: license.licenseNoHHA })
        if (license.licenseNoRNLVN !== '') licenses.push({ id: 'RNLVN', no: license.licenseNoRNLVN })
      } else {
        licenses.push({ id: 'ID', no: 'N/A' })
      }
      if (licenses.length > 2) licenses.splice(2)
      // console.log('licenses', license)
      return licenses
    }

    return {
      caregiverLicenses,
      avatarText,
    }
  },
}
</script>

<style scoped>
@media (min-width: 992px) {
  .stats-card {
    max-width: 20%;
  }
}

.card-statistics {
  background-color: #569099;
}

.card-header-text {
  color: #ffffff;
}

.card-header-right-text {
  color: #9abdc2;
}
.mycolor{
  background-color: rgba(232,62,140, 0.2);
}
</style>
