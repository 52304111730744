<template>
  <div
    dragScroll="false"
  >
    <b-sidebar
      id="sidebar-shift-history-handler"
      sidebar-class="sidebar-lg"
      :visible="isShiftHistoryHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-shift-history-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Shift: {{ shiftHistoryLocal.status === '3' ? 'Completed' : (shiftHistoryLocal.status === '4' ? 'Missed clock-in' : 'Missed clock-out') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <div class="m-2">

          <!-- alert -->
          <!-- <b-alert
            v-if="shiftHistoryLocal.status === '3' && worksafeLocal.worksafe === 'yes'"
            show
            variant="dark"
          >
            <div class="alert-body">
              <span>
                <feather-icon
                  icon="InfoIcon"
                  size="24"
                  class="mr-50 mt-25"
                />
                This shift was marked as
                <span
                  class="text-uppercase text-success"
                >
                  safe.
                </span>
              </span>
            </div>
          </b-alert> -->

          <!-- Shift Details -->
          <h5>Shift Details</h5>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="`${$urlUpload}clients/${shiftHistoryLocal.clientPicture}`"
                    :text="avatarText(shiftHistoryLocal.client_name)"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ shiftHistoryLocal.client_name }}
                </span>
                <small class="text-muted">Client</small>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ moment(shiftHistoryLocal.start).format('ddd, MMM D, YYYY') }}
                </span>
                <small class="text-muted">
                  {{ moment(shiftHistoryLocal.start_time).format('h:mm a') }}
                  -
                  {{ moment(shiftHistoryLocal.end_time).format('h:mm a') }}
                </small>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <p class="text-justify">
                  {{ shiftHistoryLocal.shift_location }}
                </p>
              </b-media>
            </b-col>
          </b-row>
          <!-- End Shift Details -->

          <hr>

          <!-- Tasks -->
          <h5 class="mt-2">
            Tasks
          </h5>
          <b-row
            v-for="(item, index) in tasksLocal"
            :key="index"
            class="mt-1"
          >
            <b-col class="">
              <b-media no-body>
                <b-media-aside>
                  <b-icon-check-circle-fill
                    v-if="item.status.toLowerCase() === 'completed'"
                    color="#B9D23E"
                    font-size="18"
                  />
                  <b-icon-x-circle-fill
                    v-else-if="['not-completed', 'wont-complete'].includes(item.status.toLowerCase())"
                    font-size="18"
                    variant="danger"
                  />
                  <b-icon-circle-fill
                    v-else
                    font-size="18"
                    variant="secondary"
                  />
                </b-media-aside>

                <b-media-body class="">
                  <h5 class="mt-0">
                    {{ item.description }}
                  </h5>

                  <b-media
                    v-if="item.notes !== ''"
                    no-body
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        variant="light-primary"
                        size="32"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          size="16"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="">
                      <b-card-text>{{ item.notes }}</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <b-row v-if="tasksLocal.length === 0">
            <b-col>
              <div class="d-flex flex-row justify-content-center align-items-center">
                <h5>No records found</h5>
              </div>
            </b-col>
          </b-row>
          <!-- End Tasks -->

          <hr>

          <!-- Mileage -->
          <h5 class="mt-2">
            Mileage
          </h5>
          <b-row
            class="mt-1"
          >
            <b-col>
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/drive_icon.svg')"
                      :alt="'drive_icon.svg'"
                    />
                  </b-avatar>
                </template>
                <span class="font-weight-bold d-block text-wrap">
                  Commute - {{ totalCommute ? parseFloat(totalCommute).toFixed(2) : 0 }} miles
                </span>
                <span class="font-weight-bold d-block text-wrap">
                  Drive - {{ totalDrive ? parseFloat(totalDrive).toFixed(2) : 0 }} miles
                </span>
              </b-media>
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
          >
            <b-col>
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <span
                  v-if="totalMileNotes !== 0"
                  class="d-block"
                >
                  <span
                    v-for="(item, index) in tasksLocal"
                    :key="index"
                    class="d-block text-justify text-wrap"
                  >
                    {{ item.mileage_notes }}
                  </span>
                </span>
                <span
                  v-else
                  class="text-justify d-block"
                >
                  N/A
                </span>
              </b-media>
            </b-col>
          </b-row>
          <!-- <b-row v-if="tasksLocal.length === 0">
            <b-col>
              <div class="d-flex flex-row justify-content-center align-items-center">
                <h5>No records found</h5>
              </div>
            </b-col>
          </b-row> -->
          <!-- End Mileage -->

          <hr>

          <!-- Expenses -->
          <h5 class="mt-2">
            Expenses
          </h5>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ worksafeLocal.expenseamount ? worksafeLocal.expenseamount : 0 }}
                </span>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <p class="text-justify">
                  {{ worksafeLocal.expensenote ? worksafeLocal.expensenote : 'N/A' }}
                </p>
              </b-media>
            </b-col>
          </b-row>
          <!-- End Expenses -->

          <hr>

          <!-- WorkSafe -->
          <!-- <h5 class="mt-2">
            WorkSafe
          </h5>
          <b-row class="mt-1 d-none">
            <b-col>
              <span
                class="text-nowrap"
              >
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="18"
                  />
                </b-avatar>
                <span
                  v-if="worksafeLocal.worksafe ? true:false"
                  class="text-nowrap ml-1 .width-5-per text-capitalize"
                >{{ worksafeLocal.worksafe === 'yes' ? 'safe' : 'hazardous' }}</span>
                <span
                  v-else
                  class="text-nowrap ml-1 .width-5-per text-capitalize"
                >N/A</span>
              </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <p class="text-justify">
                  {{ worksafeLocal.worksafecomment ? worksafeLocal.worksafecomment : 'N/A' }}
                </p>
              </b-media>
            </b-col>
          </b-row>
          <hr> -->
          <h5 class="mt-2">
            Photo
          </h5>
          <b-row
            v-if="worksafeLocal.worksafephoto ? true:false"
            class="mt-1"
          >
            <b-col>
              <span>
                <!-- :src="`data:image/png;base64, ${worksafeLocal.worksafephoto}`" -->
                <b-img
                  rounded
                  fluid
                  :src="`${$apiUrl2}mobile/worksafe/${worksafeLocal.worksafephoto}`"
                  :alt="'worksafephoto'"
                  class="d-inline-block mr-1 mb-1 pull-up myPhotos"
                  @click="onclick($apiUrl2), index = 0"
                />
              </span>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="d-flex flex-row justify-content-center align-items-center">
                <h5>No saved photos</h5>
              </div>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mt-1"
            style="width: 90px"
            @click="hide"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <CoolLightBox
      class="myPhoto"
      :items="items"
      :index="index"
      :loop="true"
      :effect="'swipe'"
      :slideshow="false"
      :use-zoom-bar="true"
      @close="index = null"
    />
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {
  BSidebar,
  BButton,
  // BAlert,
  BRow,
  BCol,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardText,
  BIconCircleFill,
  BIconCheckCircleFill,
  BIconXCircleFill,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { formatDate, avatarText } from '@core/utils/filter'
import { toRefs } from '@vue/composition-api'
import useShiftHistoryHandler from './useShiftHistoryHandler'

export default {
  components: {
    BSidebar,
    BButton,
    // BAlert,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardText,
    BIconCircleFill,
    BIconCheckCircleFill,
    BIconXCircleFill,
    CoolLightBox,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShiftHistoryHandlerSidebarActive',
    event: 'update:is-shift-history-handler-sidebar-active',
  },
  props: {
    isShiftHistoryHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    shiftHistory: {
      type: Object,
      required: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  setup(props) {
    const {
      shiftHistoryLocal,
      worksafeLocal,
      tasksLocal,
      totalMileNotes,
      totalCommute,
      totalDrive,
      items,
      index,
      onclick,
    } = useShiftHistoryHandler(toRefs(props))

    return {
      shiftHistoryLocal,
      worksafeLocal,
      tasksLocal,
      totalMileNotes,
      totalCommute,
      totalDrive,
      items,
      index,
      moment,
      onclick,

      // Filter/Formatter
      avatarText,
      formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.myPhotos {
  width: 100px !important;
  height: 100px !important;
  cursor: pointer;
  object-fit: cover;
}
</style>
