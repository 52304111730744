import { ref, watch } from '@vue/composition-api'

export default function useShiftHistoryHandler(props) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const worksafeLocal = ref([])
  const photos = ref([])
  const items = ref([])
  const index = ref(null)
  const tasksLocal = ref({})
  const totalMileNotes = ref(null)
  const totalCommute = ref(null)
  const tempTotalCommute = ref(null)
  const totalDrive = ref(null)
  const tempTotalDrive = ref(null)
  const shiftHistoryLocal = ref(JSON.parse(JSON.stringify(props.shiftHistory.value)))
  watch(props.shiftHistory, () => {
    shiftHistoryLocal.value = JSON.parse(JSON.stringify(props.shiftHistory.value))
    worksafeLocal.value = shiftHistoryLocal.value.clock_inout
    tasksLocal.value = shiftHistoryLocal.value.shift_tasks
    totalMileNotes.value = tasksLocal.value.filter(f => f.mileage_notes).length
    tempTotalCommute.value = tasksLocal.value.filter(f => f.mileage_transpo.toLowerCase() === 'commuting').map(x => +x.mileage)
    tempTotalDrive.value = tasksLocal.value.filter(f => f.mileage_transpo.toLowerCase() === 'driving').map(x => +x.mileage)
    totalCommute.value = tempTotalCommute.value.reduce((x, y) => x + y, 0)
    totalDrive.value = tempTotalDrive.value.reduce((x, y) => x + y, 0)
    // console.log('totalCommute.value', totalCommute.value)
    // console.log('totalDrive.value', totalDrive.value)
  })
  const onclick = val => {
    // photos.value = worksafeLocal.value.worksafephoto.map(x => `${require(`@/assets/images/banner/${x}`)}`)
    // photos.value = [`data:image/png;base64, ${worksafeLocal.value.worksafephoto}`]
    photos.value = [`${val}mobile/worksafe/${worksafeLocal.value.worksafephoto}`]
    items.value = photos.value
    // items.value = worksafeLocal.worksafephoto
    // console.log('this is photo console:', worksafeLocal.value)
  }

  return {
    shiftHistoryLocal,
    worksafeLocal,
    tasksLocal,
    totalMileNotes,
    totalCommute,
    totalDrive,
    items,
    index,
    onclick,
  }
}
