<template>
  <div
    no-body
  >
    <b-row class="match-height">
      <b-col
        v-for="(item, index) in stat"
        :key="index"
        xl="2"
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            :class="item.class"
            :variant="item.variant"
            size="45"
          >
            <feather-icon
              size="21"
              :icon="item.icon"
              :color="item.color"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ item.statistic }}<span class="font-medium-3">{{ item.text }}</span>
            </h2>
            <span class="font-small-3">{{ item.statisticTitle }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import {
  BRow, BCol, BCard, BAvatar,
} from 'bootstrap-vue'
import axios from '@axios'
import Vue from 'vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  unmounted() {
    this.style.remove()
  },
  setup() {
    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'
    const stat = ref()
    const getSockMsg = sockMsg => {
      if (sockMsg === true) {
        // Trigger reload or something here
        // console.log(typeof sockMsg, sockMsg)
        // eslint-disable-next-line no-use-before-define
        getStatDATA()
      }
    }
    const getStatDATA = () => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        userId: JSON.parse(localStorage.userData).id,
      })
      formData.append('data', data)
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios.post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/fetchStatistics`, formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response)
            stat.value = response.data.response.data
            // console.log('response', response.data.response.data)
          })
          .catch(error => { reject(error) })
      })
    }
    const initializeSockJs = () => {
      sock.onopen = () => {
      // console.log('Connection established.')
      }
      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === sockId) {
          getSockMsg(data.msg)
        }
      }
      sock.onclose = () => {
      // console.warn('Connection closed.')
        SockJS.call(sock, sock.url)
      }
    }
    getStatDATA()
    initializeSockJs()

    return {
      getStatDATA,
      stat,
    }
  },
}
</script>

<style>
@media (min-width: 992px) {
  .stats-card {
    max-width: 20%;
  }
}

.card-statistics {
  background-color: #569099;
}

.card-header-text {
  color: #ffffff;
}

.card-header-right-text {
  color: #9abdc2;
}
.mycolor{
  background-color: rgba(232,62,140, 0.2);
}
</style>
