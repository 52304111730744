/* eslint-disable no-return-assign */
import axios from '@axios'
import Vue from 'vue'
import { reactive } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

const today = new Date()
const pst = today.toLocaleString('en-US', {
  timeZone: 'America/Los_Angeles',
})
// eslint-disable-next-line no-unused-vars
const date = moment(pst).format('YYYY-MM-DD')

export default {
  namespaced: true,
  state: reactive({
    caregiverInfo: {
      caregiver: [],
      shifts: [],
      license: {},
    },
    scheduledShifts: [],
    openShifts: [],
    // TABLE
    historyShifts: [],
    rawHistoryItems: [],
    currentPage: 1,
    totalItems: null,
    perPage: 5,
    fields: [
      { key: 'data' },
    ],
  }),
  mutations: {
    SET_CAREGIVER_INFO: (state, payload) => (state.caregiverInfo = payload),
    SET_SCHEDULED_SHIFTS: (state, payload) => (state.scheduledShifts = payload),
    SET_OPEN_SHIFTS: (state, payload) => (state.openShifts = payload),
    SET_HISTORY_SHIFTS: (state, payload) => (state.historyShifts = payload),
    SET_RAW_ITEMS: (state, payload) => (state.rawHistoryItems = payload),
    SET_TOTAL_ITEMS: (state, payload) => (state.totalItems = payload),

    SET_ROWS: (state, payload) => (state.totalRows = payload),
    SET_PERPAGE: (state, payload) => (state.perPage = payload),
    SET_CURR_PAGE: (state, payload) => (state.currentPage = payload),
    SET_TABLEFIELDS: (state, payload) => (state.fields = payload),
  },
  getters: {
    // getItems: state => state.items,
  },
  actions: {
    request: async () => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        userId: JSON.parse(localStorage.userData).id,
      })
      formData.append('data', data)
      const accessToken = localStorage.getItem('accessToken')
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/fetchCaregiverShiftInfo`, formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
        return response
      } catch (error) {
        return error
      }
    },
    requestCaregiverInfo: async () => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        userId: JSON.parse(localStorage.userData).id,
      })
      formData.append('data', data)
      const accessToken = localStorage.getItem('accessToken')
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/fetchCaregiverInfo`, formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
        return response
      } catch (error) {
        return error
      }
    },
    filterData(state, payload) {
      const isScheduledShifts = payload.filter(f => (f.start > date && (['1', '2', '3'].includes(f.status.toString()))))
      // eslint-disable-next-line no-unused-expressions
      isScheduledShifts.length !== 0 ? state.dispatch('setScheduledShifts', isScheduledShifts) : ''
      // console.log('isScheduledShifts', isScheduledShifts)

      const isOpenShifts = payload.filter(f => (f.status.toString() === '6'))
      // eslint-disable-next-line no-unused-expressions
      isOpenShifts.length !== 0 ? state.dispatch('setOpenShifts', isOpenShifts) : ''

      const isHistoryShifts = payload.filter(f => ((['3'].includes(f.status.toString()) && moment(f.start).format('YYYY-MM-DD') < date)) || ['4', '5'].includes(f.status.toString()))
      // eslint-disable-next-line no-unused-expressions
      isHistoryShifts.length !== 0 ? state.dispatch('setHistoryShifts', isHistoryShifts) : ''
      // console.log('isHistoryShifts', isHistoryShifts.slice(0, 5))
    },

    setScheduledShifts(ctx, payload) {
      const tempData = []
      // console.log('payload:', payload.slice(0, 5))
      payload.forEach(data => {
        // console.log('data', data)
        const formData = data
        // console.log('forms', formData)
        tempData
          .push({
            shiftID: formData.id,
            clientID: formData.client,
            clientPicture: formData.clientPicture,
            client_name: formData.client_name,
            start: formData.start,
            end: formData.end,
            start_time: `${moment(formData.start).format('YYYY/MM/DD')} ${formData.start_time}`,
            end_time: `${moment(formData.end).format('YYYY/MM/DD')} ${formData.end_time}`,
            status: formData.status,
            shift_type: formData.shift_type,
            shift_tasks: formData.shift_tasks ? formData.shift_tasks : '',
            clock_inout: formData.clock_inout ? formData.clock_inout : '',
            // clock_out: formData.clock_inout[0].clock_ut ? formData.clock_inout[0].clock_ut : '',
          })
      })
      const sortedToDate = tempData.sort((a, b) => new Date(a.start.replace(/\s/, 'T')) - new Date(b.start.replace(/\s/, 'T')))
      // console.log('SET_SCHEDULED_SHIFTS', sortedToDate.slice(0, 5))
      ctx.commit('SET_SCHEDULED_SHIFTS', sortedToDate.slice(0, 5))
    },

    setHistoryShifts(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data
        tempData
          .push({
            clientID: formData.client,
            clientPicture: formData.clientPicture,
            client_name: formData.client_name,
            start: formData.start,
            end: formData.end,
            start_time: `${moment(formData.start).format('YYYY/MM/DD')} ${formData.start_time}`,
            end_time: `${moment(formData.end).format('YYYY/MM/DD')} ${formData.end_time}`,
            status: formData.status,
            shift_type: formData.shift_type,
            shift_location: formData.location,
            shift_tasks: formData.shift_tasks ? formData.shift_tasks : '',
            clock_inout: formData.clock_inout ? formData.clock_inout : '',
          })
      })
      const sortedToDate = tempData.sort((a, b) => new Date(b.start.replace(/\s/, 'T')) - new Date(a.start.replace(/\s/, 'T')))
      // console.log('SET_HISTORY_SHIFTS', sortedToDate.slice(0, 5))
      ctx.commit('SET_HISTORY_SHIFTS', sortedToDate)
      ctx.commit('SET_RAW_ITEMS', sortedToDate)
      ctx.commit('SET_TOTAL_ITEMS', sortedToDate.length)
      // ctx.dispatch('filterByShiftStatus', sortedToDate)
    },

    setOpenShifts(ctx, payload) {
      const tempData = []
      // console.log('payload:', payload)
      payload.forEach(data => {
        // console.log('data', data)
        const formData = data
        // console.log('forms', formData)
        tempData
          .push({
            clientID: formData.client,
            clientPicture: formData.clientPicture,
            client_name: formData.client_name,
            start: formData.start,
            end: formData.end,
            start_time: `${moment(formData.start).format('YYYY/MM/DD')} ${formData.start_time}`,
            end_time: `${moment(formData.end).format('YYYY/MM/DD')} ${formData.end_time}`,
            status: formData.status,
            shift_type: formData.shift_type,
            shift_tasks: formData.shift_tasks ? formData.shift_tasks : '',
            clock_inout: formData.clock_inout ? formData.clock_inout : '',
          })
      })
      const sortedToDate = tempData.sort((a, b) => new Date(a.start.replace(/\s/, 'T')) - new Date(b.start.replace(/\s/, 'T')))
      // console.log('SET_OPEN_SHIFTS', sortedToDate.slice(0, 5))
      ctx.commit('SET_OPEN_SHIFTS', sortedToDate.slice(0, 5))
    },
    filterByShiftStatus(ctx, payload) {
      if (payload[0].value === '3') {
        const filteredData = ctx.state.rawHistoryItems.filter(f => f.status === '3')
        ctx.commit('SET_HISTORY_SHIFTS', filteredData)
        ctx.commit('SET_TOTAL_ITEMS', filteredData.length)
        // console.log('this is console both: ', payload)
      } else if (payload[0].value === '4') {
        const filteredData = ctx.state.rawHistoryItems.filter(f => f.status === '4')
        ctx.commit('SET_HISTORY_SHIFTS', filteredData)
        ctx.commit('SET_TOTAL_ITEMS', filteredData.length)
      } else if (payload[0].value === '5') {
        const filteredData = ctx.state.rawHistoryItems.filter(f => f.status === '5')
        ctx.commit('SET_HISTORY_SHIFTS', filteredData)
        ctx.commit('SET_TOTAL_ITEMS', filteredData.length)
      } else if (payload[0].value === '') {
        const filteredData = ctx.state.rawHistoryItems
        ctx.commit('SET_HISTORY_SHIFTS', filteredData)
        ctx.commit('SET_TOTAL_ITEMS', filteredData.length)
      } else {
        const filteredData = ctx.state.rawHistoryItems
        ctx.commit('SET_HISTORY_SHIFTS', filteredData)
        ctx.commit('SET_TOTAL_ITEMS', filteredData.length)
      }
    },
  },

}
